/** @module store/uploads */
import * as uuid from 'uuid';
import { AppDispatch, GlobalState } from 'store/types';
import {
  validFileNameLength,
  validFileNameChars,
  validFileNameWhiteSpaceBeginning,
  validFileNameWhiteSpaceEnding,
  validFileNamePeriod,
  validNameNoEmojis,
} from 'utilities/form/rules';
import {
  createRepositoryItemSuccess,
  getRepositoryItems,
} from 'store/repositoryItems/actions';
import MetadataService, { ItemDetails } from 'services/metadata';
import StorageService, {
  UploadFile,
  UploadFolder,
  UploadStatus,
} from 'services/storage';
import { createHierarchy } from 'utilities/folder';
import { FolderDetails } from 'utilities/folder/types';
import { selectFileUploads } from './selectors';
import {
  UploadsActionType,
  UploadsEnqueueFileAction,
  UploadsEnqueueFilesAction,
  UploadsFileRequestAction,
  UploadsFileProgressAction,
  UploadsFileSuccessAction,
  UploadsFileErrorAction,
  UploadsFileCancelAction,
  UploadsClearAction,
  UploadsEnqueueFolderAction,
  UploadsFolderErrorAction,
  UploadsFolderRequestAction,
  UploadsFolderCancelAction,
  UploadsFolderProgressAction,
  UploadsNameValidateRequestAction,
  UploadsNameValidateErrorAction,
} from './types';

export const MIN_PROGRESS_INTERVAL = 0.02;

export const enqueueFile = (
  file: File,
  parentId: string,
  repoId: string,
  folderId?: string,
): UploadsEnqueueFileAction => ({
  type: UploadsActionType.UPLOADS_ENQUEUE_FILE,
  payload: {
    upload: {
      id: uuid.v4(),
      file,
      parentId,
      progress: 0,
      status: UploadStatus.ENQUEUED,
      xhr: new XMLHttpRequest(),
      folderId,
      repoId,
    },
  },
});

export const enqueueFiles = (
  files: File[],
  parentId: string,
  repoId: string,
  folderId?: string,
  status?: UploadStatus,
): UploadsEnqueueFilesAction => ({
  type: UploadsActionType.UPLOADS_ENQUEUE_FILES,
  payload: {
    uploads: files.map((file) => ({
      id: uuid.v4(),
      file,
      parentId,
      progress: 0,
      status: status || UploadStatus.ENQUEUED,
      xhr: new XMLHttpRequest(),
      folderId,
      repoId,
    })),
  },
});

export const uploadFileRequest = (id: string): UploadsFileRequestAction => ({
  type: UploadsActionType.UPLOADS_FILE_REQUEST,
  payload: { id },
});

export const uploadFileProgress = (id: string, progress: number): UploadsFileProgressAction => ({
  type: UploadsActionType.UPLOADS_FILE_PROGRESS,
  payload: {
    id,
    progress,
  },
});

export const uploadFileSuccess = (id: string): UploadsFileSuccessAction => ({
  type: UploadsActionType.UPLOADS_FILE_SUCCESS,
  payload: { id },
});

export const uploadFolderProgress = (id: string): UploadsFolderProgressAction => ({
  type: UploadsActionType.UPLOADS_FOLDER_PROGRESS,
  payload: { id },
});

export const uploadFileError = (id: string, error: Error): UploadsFileErrorAction => ({
  type: UploadsActionType.UPLOADS_FILE_ERROR,
  payload: {
    id,
    error,
  },
});

export const cancelFileUpload = (id: string): UploadsFileCancelAction => ({
  type: UploadsActionType.UPLOADS_FILE_CANCEL,
  payload: { id },
});

export const clearUploads = (): UploadsClearAction => ({
  type: UploadsActionType.UPLOADS_CLEAR,
});

export const uploadFolderRequest = (id: string): UploadsFolderRequestAction => ({
  type: UploadsActionType.UPLOADS_FOLDER_REQUEST,
  payload: {
    id,
  },
});

export const uploadFolderError = (
  id: string,
  error: Error,
  folderName?: string,
): UploadsFolderErrorAction => ({
  type: UploadsActionType.UPLOADS_FOLDER_ERROR,
  payload: {
    id,
    error,
    folderName,
  },
});

export const cancelFolderUpload = (id: string): UploadsFolderCancelAction => ({
  type: UploadsActionType.UPLOADS_FOLDER_CANCEL,
  payload: { id },
});

export const validateUploadNameRequest = (name: string): UploadsNameValidateRequestAction => ({
  type: UploadsActionType.UPLOADS_NAME_VALIDATE_REQUEST,
  payload: {
    name,
  },
});

export const validateUploadNameError = (name: string):
UploadsNameValidateErrorAction => ({
  type: UploadsActionType.UPLOADS_NAME_VALIDATE_ERROR,
  payload: {
    name,
  },
});

export function uploadFile(upload: UploadFile) {
  return async (dispatch: AppDispatch, getState: () => GlobalState): Promise<void> => {
    let lastProgress = 0;
    dispatch(uploadFileRequest(upload.id));
    try {
      const uploadUrl = await new MetadataService().getFolderUploadUrl(upload.parentId);
      const onProgress = (progress: number): void => {
        if (progress === 1 || progress - lastProgress >= MIN_PROGRESS_INTERVAL) {
          dispatch(uploadFileProgress(upload.id, progress));
          lastProgress = progress;
        }
      };
      const onSuccess = (): void => {
        dispatch(uploadFileSuccess(upload.id));
        if (upload.folderId) dispatch(uploadFolderProgress(upload.folderId));
        const { uploads } = getState();
        const remainingFilesInFolder = selectFileUploads(uploads)
          .filter((file) => (
            file.parentId === upload.parentId
            && (
              file.status === UploadStatus.ACTIVE
              || file.status === UploadStatus.ENQUEUED
            )
          ));
        if (remainingFilesInFolder.length === 0) {
          dispatch(getRepositoryItems(upload.parentId));
        }
      };
      await new StorageService(uploadUrl).uploadFile(upload, onProgress, onSuccess);
    } catch (error) {
      const uploadError = error as Error;
      if (!upload.folderId) dispatch(uploadFileError(upload.id, uploadError));
      const { uploads } = getState();
      const remainingFilesInFolder = selectFileUploads(uploads)
        .filter((file) => (
          file.parentId === upload.parentId
          && (
            file.status === UploadStatus.ACTIVE
            || file.status === UploadStatus.ENQUEUED
          )
        ));
      if (remainingFilesInFolder.length === 0) {
        dispatch(getRepositoryItems(upload.parentId));
      }
      if (upload.folderId) dispatch(uploadFolderError(upload.folderId, uploadError));
    }
  };
}

export const enqueueFolder = (
  name: string,
  files: WebkitFile[],
  parentId: string,
  repoId: string,
): UploadsEnqueueFolderAction => ({
  type: UploadsActionType.UPLOADS_ENQUEUE_FOLDER,
  payload: {
    folder: {
      id: uuid.v4(),
      parentId,
      repoId,
      files,
      name,
      progress: 0,
      status: UploadStatus.ENQUEUED,
    },
  },
});

/**
 * A Recursive thunk that fails the children uploads when create directory fails
 * @param folderInfo FolderDetails object containing name, children and files array
 * @param folderUuid uuid of the folder
 * @return A thunk action which returns a promise
 */
export function failChildUploadOnCreateDirectoryError(
  folderInfo: FolderDetails,
  folderUuid: string,
) {
  return async (dispatch: AppDispatch): Promise<void> => {
    const files = folderInfo.files.map((file) => new File([file], file.name));
    dispatch(enqueueFiles(files, '', '', folderUuid, UploadStatus.ERROR));
    Object.values(folderInfo.children).forEach((child) => {
      if (child.files.length > 0) {
        dispatch(failChildUploadOnCreateDirectoryError(child, folderUuid));
      }
    });
  };
}

/**
 * A Recursive thunk that creates directory structure and enqueues files
 * following depth first traversal
 * @param parentId Parent id of the folder
 * @param folderInfo FolderDetails object containing name, children and files array
 * @param folderId uuid of the folder
 * @return A thunk action which returns a promise
 */
export function createDirectory(parentId: string, folderInfo: FolderDetails, folderUuid: string) {
  return async (dispatch: AppDispatch): Promise<void> => {
    try {
      const folder = await new MetadataService().createFolder(folderInfo.name, parentId);
      dispatch(createRepositoryItemSuccess(folder, parentId, false));
      const files = folderInfo.files.map((file) => new File([file], file.name));
      dispatch(enqueueFiles(files, folder.id, folder.repoId, folderUuid));
      Object.keys(folderInfo.children).forEach((childFolderName) => dispatch(createDirectory(
        folder.id,
        folderInfo.children[childFolderName],
        folderUuid,
      )));
    } catch (error) {
      dispatch(uploadFolderError(folderUuid, error as Error, folderInfo.name));
      dispatch(failChildUploadOnCreateDirectoryError(folderInfo, folderUuid));
    }
  };
}

/**
 * A thunk that enqueues folder and calls createDirectory thunk
 * @param files Array of files that contains webkitRelativePath
 * @param parentId Parent id of the folder
 * @return A thunk action which returns a promise
 */
export function uploadFolder(upload: UploadFolder) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(uploadFolderRequest(upload.id));
    try {
      const hierarchy = createHierarchy(upload.files);
      const rootFolderName = Object.keys(hierarchy)[0];
      await dispatch(createDirectory(upload.parentId, hierarchy[rootFolderName], upload.id));
    } catch (error) {
      dispatch(uploadFolderError(upload.id, error as Error));
    }
  };
}

/**
 * Cancels all uploads in which the parent is the given repository item.
 * @param item A repository item
 * @return A Thunk action
 */
export function cancelUploadOnDeleteRepositoryItem(item: ItemDetails) {
  return async (dispatch: AppDispatch, getState: Function): Promise<void> => {
    const state: GlobalState = getState();
    const cancelTheseFiles = Object.values(state.uploads.files)
      .filter((file) => file.parentId === item.id && file.status !== UploadStatus.COMPLETED);
    const cancelTheseFolders = Object.values(state.uploads.folders)
      .filter((folder) => folder.parentId === item.id && folder.status !== UploadStatus.COMPLETED);
    cancelTheseFiles.forEach((file) => dispatch(cancelFileUpload(file.id)));
    cancelTheseFolders.forEach((folder) => dispatch(cancelFolderUpload(folder.id)));
  };
}

/**
 * Cancels all uploads to a given repository.
 * @param deletedRepoId The repository id
 * @return A Thunk action
 */
export function cancelUploadOnDeleteRepository(deletedRepoId: string) {
  return async (dispatch: AppDispatch, getState: Function): Promise<void> => {
    const state: GlobalState = getState();
    const cancelTheseFiles = Object.values(state.uploads.files)
      .filter((file) => file.repoId === deletedRepoId && file.status !== UploadStatus.COMPLETED);
    const cancelTheseFolders = Object.values(state.uploads.folders)
      .filter((folder) => folder.repoId === deletedRepoId
        && folder.status !== UploadStatus.COMPLETED);
    cancelTheseFiles.forEach((file) => dispatch(cancelFileUpload(file.id)));
    cancelTheseFolders.forEach((folder) => dispatch(cancelFolderUpload(folder.id)));
  };
}

export function validateName(name: string) {
  return (dispatch: AppDispatch): boolean => {
    const fileNameValid = name
      && validFileNameLength.pattern.test(name)
      && validFileNameChars.pattern.test(name)
      && validFileNameWhiteSpaceBeginning.pattern.test(name)
      && validFileNameWhiteSpaceEnding.pattern.test(name)
      && validFileNamePeriod.pattern.test(name)
      && validNameNoEmojis.pattern.test(name);

    if (fileNameValid) {
      return true;
    }
    dispatch(validateUploadNameError(name));
    return false;
  };
}
